import { Routes, Route } from "react-router-dom";
import HomeV2Page from "~/containers/home/HomeContainerV2";
import NoPageErrorPage from "~/components/boundary/NoPageError";
import AuthRoute from "~/components/auth/AuthRoute";
import loadable from "~/components/common/Loadable";
import Redirection from "~/components/common/Redirection";

// 서브 랜딩페이지
const CourseAboutPage = loadable(
  () => import("~/containers/course/CourseAboutContainer")
);
const CourseFormPage = loadable(
  () => import("~/containers/course/CourseFormContainer")
);
const CourseYoutubePage = loadable(
  () => import("~/containers/course/CourseYoutubeContainer")
);
const CourseProgramPage = loadable(
  () => import("~/containers/course/CourseProgramContainer")
);

// 약관
const PolicyPage = loadable(
  () => import("~/containers/policy/PolicyContainer")
); // 이용약관
const PrivacyPage = loadable(
  () => import("~/containers/policy/PrivacyContainer")
); // 개인정보 처리방침
const OpenSourceLicensePage = loadable(
  () => import("~/containers/policy/LicenseContainer")
); // 오픈소스 라이선스

// 스페이스
const SpacePage = loadable(
  () => import("~/containers/space/index/SpaceContainer")
);
const SpaceBoardPage = loadable(
  () => import("~/containers/space/board/SpaceBoardContainer")
);
const SpaceBookmarkPage = loadable(
  () => import("~/containers/space/bookmark/SpaceBookmarkContainer")
);
const SpaceCollectionPage = loadable(
  () => import("~/containers/space/collection/SpaceCollectionContainer")
);

// 공유
const SlideSharePage = loadable(
  () => import("~/containers/share/SlideShareContainer")
);
const SpaceSharePage = loadable(
  () => import("~/containers/share/SpaceShareContainer")
);

// 가이드
const GuidePage = loadable(() => import("~/containers/help/GuideContainer"));

// 스페이스 계정
const SpaceUserPage = loadable(
  () => import("~/containers/space/user/SpaceUserContainer")
);
const SpaceAuthPage = loadable(
  () => import("~/containers/space/auth/SpaceAuthContainer")
);
const SpaceEmailVerifyPage = loadable(
  () => import("~/components/space/user/SpaceEmailVerify")
);

// 소셜 로그인
const AuthCallbackKakao = loadable(
  () => import("~/components/auth/callback/AuthCallbackKakao")
);
const AuthCallbackNaver = loadable(
  () => import("~/components/auth/callback/AuthCallbackNaver")
);

type RouteProps = { location: any };

// TODO: - error page (404, 500)
const AppRoutes = ({ location }: RouteProps) => {
  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path="/*"
        element={
          <Routes>
            <Route path="/" element={<HomeV2Page />} />
            <Route path="/about" element={<CourseAboutPage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/share/*"
        element={
          <Routes>
            <Route path="/slide" element={<SlideSharePage />} />
            <Route path="/space/board/:id/*" element={<SpaceSharePage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/show/*"
        element={
          <Routes>
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/course/*"
        element={
          <Routes>
            <Route path="/form" element={<CourseFormPage />} />
            <Route path="/about" element={<Redirection url="/about" />} />
            <Route path="/youtube/:id?" element={<CourseYoutubePage />} />
            <Route path="/program/:id?" element={<CourseProgramPage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/login/*"
        element={
          <Routes>
            <Route path="/" element={<SpaceAuthPage />} />
            <Route path="/find-password" element={<SpaceAuthPage />} />
            <Route path="/sing-up/:step?" element={<SpaceAuthPage />} />
            <Route path="/oauth/kakao" element={<AuthCallbackKakao />} />
            <Route path="/oauth/naver" element={<AuthCallbackNaver />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/space/*"
        element={
          <AuthRoute>
            <Routes>
              <Route path="/" element={<SpacePage />} />
              <Route path="/etc-auth" element={<SpacePage />} />
              <Route path="/bookmark" element={<SpaceBookmarkPage />} />
              <Route
                path="/collection/:id?"
                element={<SpaceCollectionPage />}
              />
              <Route path="/*" element={<NoPageErrorPage />} />
            </Routes>
          </AuthRoute>
        }
      />
      <Route
        path="/space/user/:id?"
        element={
          <AuthRoute>
            <Routes>
              <Route path="/" element={<SpaceUserPage />} />
              <Route path="/*" element={<NoPageErrorPage />} />
            </Routes>
          </AuthRoute>
        }
      />
      <Route
        path="/space/board/:id/*"
        element={
          <AuthRoute>
            <Routes>
              <Route path="/" element={<SpaceBoardPage />} />
              <Route path="/c/:cid?/:option?" element={<SpaceBoardPage />} />
              {/* 평가 */}
              <Route path="/evaluate" element={<SpaceBoardPage />} />
              {/* 보드 설정 */}
              <Route path="/b/:boardId?" element={<SpaceBoardPage />} />
              {/* 설문 에디터 */}
              <Route path="/qe/:questionId?" element={<SpaceBoardPage />} />
              {/* 설문 보기 */}
              <Route path="/q/:questionId?" element={<SpaceBoardPage />} />
              {/* 현황 보기 */}
              <Route path="/dashboard" element={<SpaceBoardPage />} />
              {/* 발표 */}
              <Route path="/slide" element={<SpaceBoardPage />} />
              {/* 질문형 카드 발표 */}
              <Route path="/form-slide" element={<SpaceBoardPage />} />
              {/* 투표 만들기 */}
              <Route path="/vote/builder" element={<SpaceBoardPage />} />
              {/* 투표 상세보기 */}
              <Route path="/vote/viewer/?:id?" element={<SpaceBoardPage />} />
              {/* 스페이스 설정 */}
              <Route path="/setting" element={<SpaceBoardPage />} />
              {/* 정보 */}
              <Route path="/info" element={<SpaceBoardPage />} />
              {/* 기록 */}
              <Route path="/history" element={<SpaceBoardPage />} />
              {/* 전체 복구 */}
              <Route path="/restore" element={<SpaceBoardPage />} />
              {/* 카드 복구 */}
              <Route path="/log" element={<SpaceBoardPage />} />
              {/* 첨부 파일 리스트 보기 */}
              <Route path="/attach-viewer" element={<SpaceBoardPage />} />
              {/* 첨부 파일 하나 크게 보기 */}
              <Route path="/file-quick-viewer" element={<SpaceBoardPage />} />
              {/* 보기 모드, 갤러리, 피드, 테이블, 타임라인, etc. */}
              <Route path="/shape/:option?" element={<SpaceBoardPage />} />
              <Route path="/*" element={<NoPageErrorPage />} />
            </Routes>
          </AuthRoute>
        }
      />
      <Route
        path="/email/*"
        element={
          <Routes>
            <Route path="/" element={<SpaceEmailVerifyPage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/guide/*"
        element={
          <Routes>
            <Route path="/:id?" element={<GuidePage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route
        path="/policy/*"
        element={
          <Routes>
            <Route path="/" element={<PolicyPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/license" element={<OpenSourceLicensePage />} />
            <Route path="/*" element={<NoPageErrorPage />} />
          </Routes>
        }
      />
      <Route path="/404" element={<NoPageErrorPage />} />
      <Route path="/*" element={<NoPageErrorPage />} />
    </Routes>
  );
};

export default AppRoutes;
