import { memo, useState, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { windowOpenWithFullScreen } from "~/libs/utils";
// import { loadScriptWithKey } from "~/libs/utils";
// import { KAKAO_SDK_URI, KAKAO_SDK_KEY } from "~/libs/constants";
// import { useBaseStore } from "~/libs/store";

const IPKakaoBtn = () => {
  const location = useLocation();
  const [state, setState] = useState({ isShow: false });

  useEffect(() => {
    if (location?.pathname) {
      if (
        location.pathname.includes("/space") ||
        location.pathname.includes("/space/board") ||
        location.pathname.includes("/login") ||
        location.pathname.includes("/festival") ||
        location.pathname.includes("/analyze") ||
        location.pathname.includes("/roadmap") ||
        location.pathname.includes("/coach") ||
        location.pathname.includes("/lecture") ||
        location.pathname.includes("/share/slide")
      ) {
        // hide
        setState((prev) => ({ ...prev, isShow: false }));
      } else {
        // show
        setState((prev) => ({ ...prev, isShow: true }));
      }
    }
  }, [location?.pathname]);

  const onHandleClick = () => {
    // loadScriptWithKey(KAKAO_SDK_KEY, KAKAO_SDK_URI, async () => {
    //   const { Kakao } = window;
    //   if (!Kakao) {
    //     useBaseStore.setState({
    //       notify: {
    //         message:
    //           "카카오 비즈니스 톡 초기화 중에 오류가 있습니다.<br/>잠시 후에 다시 시도해주세요.",
    //       },
    //     });
    //     return;
    //   }
    //   if (!Kakao.isInitialized()) {
    //     Kakao.init(import.meta.env.VITE_KAKAO_JS_KEY);
    //   }
    //   Kakao.Channel.addChannel({
    //     channelPublicId: import.meta.env.VITE_IMPACTUS_KAKAO_CHANNELID, //카카오 채널 ID
    //   });
    // });

    windowOpenWithFullScreen(
      "https://pf.kakao.com/_xkxoXAn",
      "impactus-kakao-friends"
    );
  };

  if (!state.isShow) return null;

  return (
    <StyledIconButton onClick={onHandleClick}>
      <img
        src="https://developers.kakao.com/tool/resource/static/img/button/channel/add/channel_add_large.png"
        alt="카카오톡 채널 추가하기 버튼"
      />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(Button)(({ theme }) => ({
  zIndex: 9999,
  position: "fixed",
  bottom: 5,
  right: 5,
}));

export default memo(IPKakaoBtn);
