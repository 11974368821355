import format from "date-fns/format";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import differenceInHours from "date-fns/differenceInHours";
import koLocale from "date-fns/locale/ko";
import ko from "timeago.js/lib/lang/ko";
import { register } from "timeago.js";

register("ko", ko);

export const formatDate = (date: string): string => {
  const d = new Date(date);
  const now = Date.now();
  const diff = now - new Date(date).getTime();
  // less than 5 minutes
  if (diff < 1000 * 60 * 5) {
    return "방금 전";
  }
  if (diff < 1000 * 60 * 60 * 24) {
    return distanceInWordsToNow(d, { addSuffix: true, locale: koLocale });
  }
  if (diff < 1000 * 60 * 60 * 36) {
    return "어제";
  }
  if (diff < 1000 * 60 * 60 * 24 * 7) {
    return distanceInWordsToNow(d, { addSuffix: true, locale: koLocale });
  }
  return format(d, "yyyy년 M월 d일");
};

/**
 * NOTE:
 * @param: x: new Date().getTime(),
 * @return: string format => yyyy-MM-dd | Unknown
 */
export const transform = (x: any) => {
  if (!x) return "Unknown";
  const regdate: any = new Date(x);
  const ret = `${regdate.getFullYear()}-${
    parseInt(regdate.getMonth() + 1) < 10
      ? "0" + (regdate.getMonth() + 1)
      : regdate.getMonth() + 1
  }-${
    parseInt(regdate.getDate()) < 10
      ? "0" + regdate.getDate()
      : regdate.getDate()
  }`;
  return ret;
};

/**
 * NOTE:
 * @param: x: new Date().getTime(),
 * @return: string format => yyyy-MM
 */
export const transformMonth = (x: any) => {
  if (!x) return "Unknown";
  const regdate: any = new Date(x);
  const ret = `${regdate.getFullYear()}-${
    parseInt(regdate.getMonth() + 1) < 10
      ? "0" + (regdate.getMonth() + 1)
      : regdate.getMonth() + 1
  }`;
  return ret;
};

/**
 * NOTE:
 * @param: day: yyyy-MM-dd, time: hh:mm
 * @return: string format => hh:mm
 */
export const transformTime = (day: string, time: string = "hh:mm") => {
  if (!day || !time) return "Unknown";
  const hh =
    parseInt(time.split(":")[0]) < 10
      ? `0${time.split(":")[0]}`
      : time.split(":")[0];
  const mm = time.split(":")[1];
  const timeFormat = `${hh}:${mm}`;

  return new Date(`${day} ${timeFormat}`).toLocaleTimeString().substring(0, 8);
};

/**
 * NOTE:
 * @param: timestamp(number)
 * @return: string format => yyyy-MM-dd hh:mm:ss
 */
export const transformDayAndTime = (x: number, isSec: boolean = true) => {
  if (!x) return "Unknown";
  if (isSec) {
    return format(x, "yyyy-MM-dd HH:mm:ss");
  }
  return format(x, "yyyy-MM-dd HH:mm");
};

/**
 * NOTE:
 * @param: timestamp(number)
 * @return: string format => yyyy-MM-dd hh:mm:ss
 */
export const transformForFileAttach = (x: number, isSec: boolean = true) => {
  if (!x) return "Unknown";
  if (isSec) {
    return format(x, "yyyyMMdd_HHmmss");
  }
  return format(x, "yyyyMMdd_HHmm");
};

/**
 * NOTE:
 * @param: timestamp(number)
 * @return: string format => hh:mm:ss
 */
export const transformOnlyTime = (x: number) => {
  if (!x) return "Unknown";
  return format(x, "HH:mm");
};

/**
 * 한국 포맷으로 반환
 *
 * @param x
 * @returns
 */
export const transformKR = (x: any) => {
  if (!x) return "Unknown";
  const regdate = new Date(x);
  const ret = `${regdate.toLocaleDateString(
    "ko-KR"
  )} ${regdate.toLocaleTimeString("ko-KR", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
  return ret;
};

/**
 * 한국 포맷으로 반환
 *
 * @param x
 * @returns
 */
export const transformKROnlyHour = (x: any) => {
  if (!x) return "Unknown";
  const regdate = new Date(x);
  const ret = `${regdate.toLocaleDateString(
    "ko-KR"
  )} ${regdate.toLocaleTimeString("ko-KR", {
    hour: "2-digit",
  })}`;
  return ret;
};

// 생년월일로 나이 계산
export const calcAge = (birth: any) => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = ("0" + month) as any;
  if (day < 10) day = ("0" + day) as any;
  const monthDay = month + day;
  birth = birth.replace("-", "").replace("-", "");
  const birthdayy = birth.substr(0, 4);
  const birthdaymd = birth.substr(4, 4);
  const age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
  return age;
};

export const transformAge = (x: any) => {
  const years = calcAge(x);
  if (years >= 10 && years < 20) {
    return "10~19";
  } else if (years >= 20 && years < 30) {
    return "20~29";
  } else if (years >= 30 && years < 40) {
    return "30~39";
  } else if (years >= 40 && years < 50) {
    return "40~49";
  } else if (years >= 50 && years < 60) {
    return "50~59";
  } else if (years > 60) {
    return "60~";
  } else {
    return "~10";
  }
};

// 생년월일 변환
export const transformBirthDay = (x: any) => {
  if (!x) return "Unknown";
  return `${x.substring(4, 6)}/${x.substring(6, 8)}`;
};

// 비디오 기간 계산
export const transformVideoInterval = (seconds: any) => {
  const secondsNumber = parseInt(seconds, 10);
  let hours: any = Math.floor(secondsNumber / 3600);
  let minutes: any = Math.floor((secondsNumber - hours * 3600) / 60);
  let totalSeconds: any = secondsNumber - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (totalSeconds < 10) {
    totalSeconds = `0${totalSeconds}`;
  }
  return `${hours}:${minutes}:${totalSeconds}`;
};

// 시간초로 분,초 반환
export const durationFormat = (seconds: any) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = `0${date.getUTCSeconds()}`.slice(-2);
  if (hh) {
    return `${hh}:${`0${mm}`.slice(-2)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

// 24시간(1일) 지났는지 여부 체크
export const day24HourCheck = (
  startTime: string,
  day: any,
  time: number = 24
) => {
  const nowTime = `${
    new Date().getHours() < 10
      ? `0${new Date().getHours()}`
      : new Date().getHours()
  }:${new Date().getMinutes()}`;
  const nowDate = new Date(`${transform(new Date().getTime())} ${nowTime}`);
  const startDate = new Date(
    `${transform(day?.getTime() || day)} ${startTime}`
  );
  return differenceInHours(startDate, nowDate) < time;
};

// 날짜의 9시간 더하기(한국 시간)
export const utcAdd9HourForKR = (x: any) => {
  // UTC + 9시간
  const timeAdd9Hour = new Date(x);
  timeAdd9Hour.setHours(timeAdd9Hour.getHours() + 9); // UTC timezone +9 hours
  return timeAdd9Hour;
};

// 시간대 구하기
// REF: https://ryusm.tistory.com/141

/**
 *
 * @param {*} now 현재
 * @param {*} day 과거 n일
 * @returns {Date} beforeDay 전 날짜 구하기: 기본 -30일
 */
export const getBeforeNdays = (now, beforeDay = 30) => {
  const year = now.getFullYear();
  const month = now.getMonth();
  const date = now.getDate();
  const beforeNday = new Date(year, month, date - beforeDay);

  return beforeNday;
};

// 두 milliseconds 간의 hours만큼 차이가 나는지 체크
export const calcDiffTime = ({ time1, time2, hours = 24 }) => {
  console.log("calc diff time: ", differenceInHours(time1, time2));
  return differenceInHours(time1, time2) >= hours;
};
